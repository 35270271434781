const zoomOptions = {
  zoom: {
    wheel: {
      enabled: true,
      speed: 0.05
    },
    drag: {
      enabled: true
    },
    pinch: {
      enabled: true
    },
    mode: 'x'
  },
  pan: {
    enabled: true,
    mode: 'x',
    modifierKey: 'shift'
  }
}

const chartOptions = {
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    zoom: zoomOptions,
    legend: {
      labels: {
        usePointStyle: true,
        pointStyle: 'rectRounded'
      },
      position: 'top'
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          let label = context.dataset.label || ''

          if (label) {
            label += ': '
          }
          if (context.parsed.y !== null && context.datasetIndex !== 0) {
            label += new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD'
            }).format(context.parsed.y)
          } else {
            label += context.parsed.y
          }
          return label
        }
      }
    }
  },

  scales: {
    x: {
      position: 'bottom',
      type: 'time',
      time: {
        unit: 'day',
        displayFormats: {
          day: 'M/d/yy'
        }
      }
    },
    y: {
      title: {
        display: true,
        text: 'Average Price Point'
      },
      position: 'left',
      min: 0,
      // max: 5.0,
      grid: {
        drawOnChartArea: false,
        lineWidth: 0.5
      },
      ticks: {
        stepSize: 0.5,
        callback: function (value) {
          return '$' + parseFloat(value).toFixed(2)
        }
      }
    },
    y2: {
      title: {
        display: true,
        text: 'Quantity'
      },
      stacked: true,
      position: 'right',
      min: 0,
      // max: 250,
      grid: {
        drawOnChartArea: true,
        lineWidth: 0.5
      },
      ticks: {
        callback: value => {
          return value
        },
        stepSize: 10
      }
    },
    y3: {
      title: {
        display: true,
        text: 'Dollars'
      },
      stacked: true,
      position: 'right',
      min: 0,
      // max: 10,
      grid: {
        drawOnChartArea: true,
        lineWidth: 0.5
      },
      ticks: {
        callback: value => {
          return `$${value}`
        },
        stepSize: 50
      }
    }
  }
}

export default chartOptions